import "./App.css";
import { BsGithub, BsLinkedin, BsFillEnvelopeFill } from "react-icons/bs";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Hi, I'm Dominik</h1>
        <p>Please use the links below to learn more about me!</p>
        <div>
          {/*div for links*/}
          <a
            className="App-link"
            href="https://www.linkedin.com/in/dominik-d-312b1710a/"
            target="_blank"
            rel="external noreferrer"
          >
            <BsLinkedin />
          </a>
          <a
            className="App-link"
            href="https://github.com/ddanko"
            target="_blank"
            rel="external noreferrer"
          >
            <BsGithub />
          </a>
          <a className="App-link" href="mailto:Dominik@VDanko.com">
            <BsFillEnvelopeFill />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
